<template>
  <Wrapper class="max-w-2xl">
    <div class="mb-3 text-left">
      <h2 class="text-xl"><b>Información del nuevo usuario</b></h2>
      <p class="text-gray-500">
        Ingrese la información del usuario y el tipo de rol administrativo
      </p>
    </div>
    <form @submit.prevent="createUser" class="mx-auto max-w-2xl">
      <div class="w-full grid grid-cols-1 lg:grid-cols-2 gap-4">
        <BaseInput
          type="text"
          label="Nombre completo"
          placeholder="Ej: Carlos Andrés"
          v-model="form.name"
          :required="true"
        />
        <BaseInput
          type="text"
          label="Número de celular"
          v-model="form.phone"
          placeholder="Ej: +573103102001"
          :required="true"
        />
        <BaseInput
          type="text"
          label="Correo electrónico"
          v-model="form.email"
          placeholder="Ej: consumidor@consumidor.com"
          :required="true"
        />
        <BasePassword
          label="Contraseña"
          placeholder="*********"
          v-model="form.password"
          :required="true"
        />
        <BaseSelect
          label="Tipo de rol"
          :selectOptions="formOptions.optionsRoles"
          v-model="form.role"
          :required="true"
        />
        <BaseSelect
          v-if="isSuper"
          label="Entidad"
          :selectOptions="companiesOptions"
          v-model="form.company_id"
          :required="true"
        />
      </div>
      <div v-if="feedback">
        <p class="mt-8 text-sm text-center text-red-500">{{ feedback }}</p>
      </div>
      <div
        class="w-full mt-8 mr-auto flex flex-col lg:flex-row-reverse lg:justify-end gap-4"
      >
        <Button
          type="submit"
          styles="secondary"
          :block="true"
          :loading="loading"
          :disable="loading"
        >
          Guardar
        </Button>
        <Button
          type="button"
          :danger="true"
          :invert="true"
          :block="true"
          :disable="loading"
          @click.native="$router.go(-1)"
        >
          Cancelar
        </Button>
      </div>
    </form>
  </Wrapper>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  data() {
    return {
      loading: false,
      feedback: null,
      form: {
        name: '',
        phone: '',
        email: '',
        role: '',
        password: '',
        is_active: true,
        company_id: '',
      },
      companiesOptions: null,
    }
  },
  computed: {
    ...mapState({ formOptions: 'form' }),
    ...mapGetters('session', ['isSuper']),
  },
  created() {
    this.fetchCompanies()
  },
  methods: {
    async createUser() {
      if (this.invalidForm()) return
      this.loading = true
      let response = await this.$api.users.createUser(this.form)
      this.loading = false

      if (response.status !== 200) {
        this.$showNotification('error', response.data)
        return
      }

      this.$showNotification('success', 'Se creo el usuario correctamente')
      this.$router.go(-1)
    },
    async fetchCompanies() {
      let response = await this.$api.companies.getCompanies()

      if (response.status !== 200) return
      this.companiesOptions = response.data.result.map((company) => ({
        id: company.key,
        name: company.name,
      }))
    },
    invalidForm() {
      this.feedback = null

      if (this.$validator.isEmpty(this.form.name)) {
        this.feedback = 'Introduzca el nombre completo del usuario'
        return true
      }
      if (this.$validator.isEmpty(this.form.phone)) {
        this.feedback = 'Introduzca un número de teléfono'
        return true
      }
      this.form.phone = this.form.phone.replace(/\s/g, '')
      if (!this.$validator.isMobilePhone(this.form.phone, 'es-CO')) {
        this.feedback = 'Introduzca un número de teléfono válido'
        return true
      }
      if (this.$validator.isEmpty(this.form.email)) {
        this.feedback = 'Introduzca un correo electrónico'
        return true
      }
      if (!this.$validator.isEmail(this.form.email)) {
        this.feedback = 'Correo electrónico inválido'
        return true
      }
      if (this.$validator.isEmpty(this.form.password)) {
        this.feedback = 'Introduzca una contraseña'
        return true
      }
      if (!this.$validator.isStrongPassword(this.form.password)) {
        this.feedback =
          'La contraseña debe contener mínimo: 8 caracteres, 1 mayúscula, 1 simbolo, 1 número'
        return true
      }
      if (this.$validator.isEmpty(this.form.role)) {
        this.feedback = 'Seleccione un rol'
        return true
      }
      if (this.isSuper && this.$validator.isEmpty(this.form.company_id)) {
        this.feedback = 'Seleccione una entidad'
        return true
      }
      return false
    },
  },
}
</script>

<style></style>
