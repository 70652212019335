<template>
  <div>
    <AddUser />
  </div>
</template>

<script>
import AddUser from '@/components/Dashboard/Users/AddUser'
export default {
  components: {
    AddUser,
  },
}
</script>

<style></style>
